export enum MessageType {
  BOT_MESSAGE = 1,
  USER_MESSAGE = 2,
  HUMAN_BOT_MESSAGE = 3,
}

export enum TaskStatus {
  DRAFT = 1,
  ACTIVE = 2,
  PAUSED = 3,
  COMPLETED = 4,
}

export enum TaskType {
  HUMAN_TO_BOT_COLLECTION = 1,
  ANNOTATION = 2,
  STATIC_ANNOTATION = 4,
  QUIZ = 7,
}

export interface Task {
  id: number;
  public_id: string;
  uuid: string;
  public_name: string;
  instruction: string;
  markdown_instruction?: string;
  human_teacher_instruction?: string;
  bot_teacher_instruction?: string;
  type: TaskType;
  prolific_redirect_url: string;
  max_dialogues_to_collect?: number | null;
  max_dialogues_to_collect_per_teacher: number;
  require_scenario: boolean;
  min_num_ai_messages_to_collect_per_dialogue?: number;
  annotate_human_messages: boolean;
  annotate_last_message_only: boolean;
  use_adhoc_assignment_strategy: boolean;
  automatically_select_scenario: boolean;
  display_search_results?: string;
  include_internal_user_annotated_dialogues: boolean;
}

interface Label {
  data: any;
}

export interface Message {
  id: number;
  text: string;
  type: MessageType;
  ts?: number;
  is_typing_placeholder?: boolean;
  created_by?: number;
  sent_to?: number;
  emoji_reaction?: string;
  bot_response?: string;
  bot_suggestion?: string;
  labels?: Label[];
  extras?: any[];
  state?: any;
}

export enum DialogueState {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
}

export enum ActivityState {
  NOT_STARTED = 1,
  STARTED = 2,
  PAUSED = 3,
  COMPLETED = 4,
  SKIPPED = 5,
}

export enum DialogueActivityType {
  COLLECTION = 1,
  ANNOTATION = 2,
}

export type StaticContextFormat = "default" | "retrieval";

export interface Dialogue {
  id: number;
  state: DialogueState;
  time_spent: number;
  scenario: number;
  activity?: DialogueActivity;
  static_context_data?: { context: string; format: StaticContextFormat };
}

export interface DialogueFeedbackForm {
  value: string;
}

export interface FeedbackForm {
  value: string;
}

export type FeedbackFormData = { [k: string]: FormDataEntryValue };

export interface DialogueActivity {
  id: number;
  dialogue: number;
  task: number;
  state: ActivityState;
  time_spent: number;
}

export interface User {
  pk: number;
  email: string;
  first_name?: string;
  username?: string;
  is_staff: boolean;
  is_professor: boolean;
}

export interface ScenarioTopic {
  name: string;
}

export interface Scenario {
  id: number;
  name: string;
  context: string;
  topic: ScenarioTopic;
}

export interface BotResponseOption {
  text: string;
  model: string;
  extras?: any[];
  state?: any;
}

export type ModelProvider = "openai_legacy" | "inflection" | "openai";

export interface SimpleUser {
  id: number;
  email: string;
}

export interface SimpleMessage {
  id: number;
  text: string;
  dialogue_id: number;
}

export interface Bookmark {
  id: number;
  created_at: string;
  created_by: SimpleUser;
  message: SimpleMessage;
  note: string;
}

export interface Page<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export type ModelParams = { [key: string]: any };

export interface SerializedPolicyTopic {
  id: number;
  topic: string;
  version: number;
  category: string;
}

export interface SerializedPolicyExampleCompletion {
  id?: number;
  text: string;
  type: "HUMAN" | "AI" | "META";
  quality: "GOOD" | "BAD" | "BEST";
  explanation?: string;
}

export interface SerializedPolicyDetailExample {
  example: {
    id?: number;
    detail_id: number;
  };
  turns: Array<{
    id?: number;
    text: string;
    type: "HUMAN" | "AI" | "META";
  }>;
  completions: SerializedPolicyExampleCompletion[];
}

export interface SerializedCompletePolicyDetail {
  detail: {
    id: number;
    topic_id: number;
    detail: string;
  };
  examples: SerializedPolicyDetailExample[];
}

export type SerializedPolicyBatchResponse = {
  topic: SerializedPolicyTopic;
  details: SerializedCompletePolicyDetail[];
}[];

export type MessagesSelection = Record<number | string, MessagePreference>;
export type MessagesSelectionHistory = Record<
  number | string,
  MessagesSelection
>;

// DEPRECATED: Only used for beat the bot preview screen
export enum MessagePreference {
  HUMAN = "H",
  BOT = "B",
}
